import React from "react";
import styled from "styled-components";
import "../styles/carousel.css";
import { Carousel } from "react-responsive-carousel";
import MouseTooltip from "react-sticky-mouse-tooltip";
import DesktopIframe from "../video/desktop-iframe";

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  // background-color: lightcoral;

  flex: 1;

  padding: 60px 0;

  cursor: none;
  user-select: none;
  z-index: 0;

  & .carousel,
  & .carousel .slider-wrapper,
  & .carousel .slide {
    max-height: calc(100vh - 300px);
  }

  @media (max-width: 500px) {
    padding: 75px 0 25px;
    cursor: pointer;
    user-select: auto;
  }
`;

const InnerGalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  max-height: calc(100vh - 300px);
  z-index: 0;
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  padding: 0 90px;

  max-height: calc(100vh - 300px);

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    max-height: calc(100vh - 300px);
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const Caption = styled.div`
  width: 26px;
  height: 36px;

  & div {
    width: 26px;
    height: 36px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  & .current-slide {
    position: absolute;
    top: 0;
    left: 0;
  }

  & .total-slides {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  & .separator {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    height: 22px;
  }

  & p {
    position: relative;
    margin: 0;
  }

  & span:after {
    content: "";
    border-right: 1px solid #000;
    content: "";
    position: absolute;
    width: 0;
    height: 27px;
    bottom: 0;
    left: 0;
    transform: rotate(45deg);
  }
`;

const CarouselButton = styled.button`
  height: 100%;
  position: absolute;

  padding: 0;

  background-color: transparent;
  border: 0;
  -webkit-appearance: none;

  z-index: 100;
`;

const Prev = styled(CarouselButton)`
  left: 0;
  width: ${props => (props.isPlaying ? "90px" : "calc(50% - 50px)")};
`;

const Next = styled(CarouselButton)`
  right: 0;
  width: ${props => (props.isPlaying ? "90px" : "calc(50% - 50px)")};
`;

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      totalSlides: 1,
      isMouseTooltipVisible: true,
      windowWidth: 769,
      rotation: 0,
      playing: false,
    };
  }

  componentDidMount(props) {
    const slideTotal = this.props.content.length;
    this.setState({
      totalSlides: slideTotal,
    });

    // Get window dimensions on load
    if (typeof window !== `undefined`) {
      let windowWidth = window.innerWidth;
      this.setState(
        {
          windowWidth: windowWidth,
        },
        () => {
          if (this.state.windowWidth <= 768) {
            this.setState({
              isMouseTooltipVisible: false,
            });
          } else {
            this.setState({
              isMouseTooltipVisible: true,
            });
          }
        }
      );
    }

    // Find first slide
    const allSlides = document.querySelector(".slide");
    const matches = allSlides.querySelector(".react-player");
    if (matches !== null) {
      matches.remove();
    }
  }

  next = () => {
    this.setState(state => ({
      currentSlide: state.currentSlide + 1,
      rotation: state.rotation + 90,
      playing: false,
    }));
  };

  prev = () => {
    this.setState(state => ({
      currentSlide: state.currentSlide - 1,
      rotation: state.rotation - 90,
      playing: false,
    }));
  };

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }

    this.setState({
      playing: false,
    });
  };

  updateVideoPlayState = (playState, evt) => {
    // evt.preventDefault();
    this.setState(state => ({
      playing: playState,
    }));
  };

  render() {
    const galleryContent = this.props.content.map((content, index) => {
      if (content.slice_type === "image") {
        return (
          <ImageContainer key={index}>
            <div className="content">
              <img
                src={content.primary.image.url}
                alt={content.primary.image.alt}
              />
            </div>
          </ImageContainer>
        );
      } else if (content.slice_type === "video") {
        return (
          <DesktopIframe
            video={content.primary.video.embed_url}
            thumbnail={content.primary.video.thumbnail_url}
            width={content.primary.video.width}
            height={content.primary.video.height}
            vimeoId={content.primary.video.video_id}
            autoPlay={false}
            playing={this.state.playing}
            key={content.primary.video.video_id}
            updateVideoPlayState={this.updateVideoPlayState}
            playsInline={true}
          />
        );
      }
    });

    return (
      <GalleryContainer>
        <MouseTooltip
          visible={this.state.isMouseTooltipVisible}
          offsetX={15}
          offsetY={10}
          style={{
            cursor: "none",
            zIndex: 100,
            transform: `rotate(${this.state.rotation}deg)`,
          }}
        >
          <Caption>
            <div>
              <p className="current-slide">{this.state.currentSlide + 1}</p>
              <p className="separator">
                <span></span>
              </p>
              <p className="total-slides">{this.state.totalSlides}</p>
            </div>
          </Caption>
        </MouseTooltip>

        <InnerGalleryContainer>
          {galleryContent.length > 1 ? (
            <>
              <Prev onClick={this.prev} />
              <Next onClick={this.next} />
              <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                infiniteLoop={true}
                useKeyboardArrows={true}
                showArrows={false}
                selectedItem={this.state.currentSlide}
                onChange={this.updateCurrentSlide}
              >
                {galleryContent}
              </Carousel>
            </>
          ) : (
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              infiniteLoop={false}
              useKeyboardArrows={false}
              showArrows={false}
              selectedItem={0}
            >
              {galleryContent}
            </Carousel>
          )}
        </InnerGalleryContainer>
      </GalleryContainer>
    );
  }
}

export default Gallery;
