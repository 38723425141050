import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: ${props => props.aspectRatio + `%`};

  & iframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    height: 100%;

    max-height: calc(100vh - 300px);

    // @media (max-width: 1024px) {
    //   padding: 25px 0 10px 0;
    // }
  }
`;

const Playbutton = styled.div`
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 44px;

  border-color: transparent transparent transparent #fff;
  transition: 100ms opacity ease;
  cursor: pointer;

  border-style: solid;
  border-width: 22px 0 22px 40px;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 20000;
`;

const Poster = styled.div`
  opacity: ${props => (props.visible === true ? 1 : 0)};
  z-index: ${props => (props.visible === true ? 1 : -1)};

  transition: 1000ms opacity ease;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  max-height: calc(100vh - 300px);
  // padding: 0 90px;

  & img {
    z-index: 100;

    width: 100%;
    height: 100%;
    object-fit: contain;

    max-height: calc(100vh - 300px);

    // @media (max-width: 1024px) {
    //   max-height: calc(100vh - 200px - 50px);
    //   padding: 25px 0 10px 0;
    // }
  }
`;

const IframeWrapper = styled.div`
  position: relative;
  padding: 0 90px;
  max-height: calc(100vh - 300px);

  // @media (max-width: 1024px) {
  //   max-height: calc(100vh - 200px - 50px);
  // }
`;

async function getVimeoThumbnail(vimeoId, width, height) {
  let response = await fetch(
    `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${vimeoId}&width=${width}&height=${height}&quality=1080`
  );
  let data = await response.json();
  return data;
}

let vimeoThumbnail;
const DesktopIframe = ({
  video,
  thumbnail,
  width,
  height,
  vimeoId,
  playing,
  updateVideoPlayState,
  playsInline,
}) => {
  const [videoThumbnail, setVideoThumbnail] = useState("");

  useEffect(() => {
    if (height > width) {
      vimeoThumbnail = getVimeoThumbnail(vimeoId, 1080, 1920);
      vimeoThumbnail.then(result => {
        setVideoThumbnail(result.thumbnail_url);
      });
    } else {
      vimeoThumbnail = getVimeoThumbnail(vimeoId, 1920, 1080);
      vimeoThumbnail.then(result => {
        setVideoThumbnail(result.thumbnail_url);
      });
    }
  });

  return (
    <IframeWrapper key={vimeoId}>
      <IframeContainer aspectRatio={(height / width) * 100}>
        <Poster visible={playing === false ? true : false}>
          <Playbutton onClick={() => updateVideoPlayState(true)} />
          <img src={videoThumbnail} />
        </Poster>

        <ReactPlayer
          className="react-player"
          url={video}
          width="100%"
          height="100%"
          playing={playing}
          controls={false}
          volume={1}
          onPause={() => updateVideoPlayState(false)}
          onEnded={() => updateVideoPlayState(false)}
          config={{
            vimeo: {
              playerOptions: {
                playsinline: playsInline,
                portrait: false,
                byline: false,
                title: false,
              },
            },
          }}
          loading="lazy"
        />
      </IframeContainer>
    </IframeWrapper>
  );
};

export default DesktopIframe;
