import React, { useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import styled from "styled-components";
import Helmet from "react-helmet";
import { use100vh } from "react-div-100vh";

// Components
import Gallery from "../components/images/gallery";
import MobileGallery from "../components/images/mobile-gallery";

const ProjectContainer = styled.div`
  min-height: calc(100vh - 55px);

  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;

  @media (max-width: 1070px) {
    display: block;
    flex-direction: unset;
  }

  @media (max-width: 500px) {
    min-height: 100%;
    // overflow-y: hidden;
  }
`;

const ProjectDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;

  flex: 0 0 100%;
  padding: 0 60px;

  @media (max-width: 1070px) {
    padding: 0;
  }

  @media (max-width: 500px) {
    padding: 0 50px 0 25px;
  }
`;

const ProjectText = styled.div`
  flex: 0 0 calc(50vw - 144px - 30px);

  & h1,
  & p {
    margin: 0 1em 0 0;
  }

  & a {
    position: relative;
  }

  a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: #000;
    visibility: visible;
  }

  @media (max-width: 1070px) {
    flex: 1;
    padding: 0 120px;

    max-width: 720px;
    margin: 0 auto;
  }

  @media (max-width: 700px) {
    padding: 0 90px;
  }

  @media (max-width: 500px) {
    // padding: 0 60px;
    padding: 0;
  }
`;

const Headline = styled.div`
  display: block;

  & h1,
  & p {
    text-transform: uppercase;
    font-size: 12px;
    display: inline;
  }
`;

function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

const IS_BROWSER = typeof window !== "undefined";
const Project = ({ location, data: staticData }) => {
  const data = useMemo(() => {
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;
    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  const [windowWidth, setWindowWidth] = useState(769);
  const [isTouch, setisTouch] = useState(false);
  const windowSizeOnResize = useWindowSize();
  const windowHeight = use100vh();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Get window dimensions on load
    if (typeof window !== `undefined`) {
      let windowWidthOnLoad = window.innerWidth;
      setWindowWidth(windowWidthOnLoad);

      if ("ontouchstart" in document.documentElement) {
        setisTouch(true);
        document.body.style.overflow = "hidden";
      } else {
        setisTouch(false);
        document.body.style = "";
      }
    }
  });

  let findSeoImage = data.prismicProject.data.body.filter(
    content => content.slice_type === "image"
  );

  let seoImage;
  if (findSeoImage.length >= 1) {
    seoImage = findSeoImage[0].primary.image.url;
  } else {
    seoImage = "";
  }

  return (
    <React.Fragment>
      <Helmet
        title={`${data.prismicProject.data.title.text} – Patrick Eakin Young`}
        meta={[
          {
            name: "description",
            content: `${data.prismicProject.data.text.text}`,
          },
          {
            name: "og:image",
            content: `${seoImage}`,
          },
          {
            name: "og:image:secure_url",
            content: `${seoImage}`,
          },
          {
            name: "og:description",
            content: `${data.prismicProject.data.text.text}`,
          },
          {
            name: "og:image:width",
            content: `1200`,
          },
          {
            name: "og:image:height",
            content: `630`,
          },
          {
            name: "og:locale",
            content: `en`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicProject.data.title.text} – Patrick Eakin Young`,
          },
          {
            name: "twitter:description",
            content: `${data.prismicProject.data.text.text}`,
          },
          {
            name: "twitter:card",
            content: `summary_large_image`,
          },
          {
            name: "twitter:image",
            content: `${seoImage}`,
          },
        ]}
      />

      <ProjectContainer>
        {(isTouch === true || windowSizeOnResize.width <= 768) && (
          <MobileGallery
            content={data.prismicProject.data.body}
            data={data.prismicProject.data}
            windowHeight={windowHeight}
          />
        )}

        {isTouch === false && windowSizeOnResize.width > 768 && (
          <React.Fragment>
            <Gallery content={data.prismicProject.data.body} />
            <ProjectDetails>
              <ProjectText>
                <Headline>
                  <h1>{data.prismicProject.data.title.text}</h1>
                  <p>{data.prismicProject.data.role.text}</p>
                </Headline>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.prismicProject.data.text.html,
                  }}
                />
              </ProjectText>
            </ProjectDetails>
          </React.Fragment>
        )}
      </ProjectContainer>
    </React.Fragment>
  );
};

export const query = graphql`
  query Projects($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        role {
          text
        }
        text {
          html
          text
        }
        body {
          ... on PrismicProjectBodyImage {
            id
            primary {
              image {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
            }
            slice_type
          }
          ... on PrismicProjectBodyVideo {
            id
            slice_type
            primary {
              video {
                embed_url
                thumbnail_url
                video_id
                width
                height
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default Project;
