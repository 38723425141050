import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { use100vh } from "react-div-100vh";
import "../styles/plyr.css";

const IframeWrapper = styled.div`
  position: relative;
  z-index: 1;

  user-select: all;

  max-height: calc(${props => props.windowHeight}px - 300px);
`;

const IframeContainer = styled.div`
  position: relative;

  & .plyr__video-embed {
    padding-bottom: ${props => props.aspectRatio}%;
  }

  & .plyr__video-embed__container {
    transform: translateY(0) !important;
  }

  & .plyr--video {
    max-height: calc(${props => props.windowHeight}px - 300px);
  }

  & iframe {
    max-height: calc(${props => props.windowHeight}px - 300px);
  }

  & .plyr__video-embed__container {
    padding-bottom: ${props => props.aspectRatio}% !important;

    &::after {
      content: "";
      background: transparent;
      width: 100%;
      height: 100%;
      min-height: 1px;
      position: absolute;
      top: 0;
    }
  }
`;

const MobileIframe = ({
  video,
  thumbnail,
  width,
  height,
  vimeoId,
  playing,
  updateVideoPlayState,
  playsInline,
  id,
}) => {
  const windowHeight = use100vh();
  const [videoPlyr, setVideoPlyr] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      const player = new Plyr(`#video-${vimeoId}`, {
        autoplay: false,
        muted: false,
        loop: {
          active: true,
        },
        hideControls: false,
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
        },
        vimeo: {
          byline: false,
          portrait: false,
          title: false,
          speed: true,
          transparent: true,
          controls: false,
        },
      });

      setVideoPlyr(player);

      player.on("play", event => {
        updateVideoPlayState(true);
      });

      player.on("pause", event => {
        updateVideoPlayState(false);
      });
    }
  }, [vimeoId, setVideoPlyr]);

  return (
    <IframeWrapper key={vimeoId} windowHeight={windowHeight}>
      <IframeContainer
        aspectRatio={(height / width) * 100}
        className={height > width ? `portrait-video` : `landscape-video`}
        windowHeight={windowHeight}
      >
        <div
          className="plyr__video-embed"
          id={`video-${vimeoId}`}
          data-plyr-config='{ "controls": ["play-large"] }'
          dangerouslySetInnerHTML={{
            __html: video,
          }}
        />
      </IframeContainer>
    </IframeWrapper>
  );
};

export default MobileIframe;
