import React from "react";
import styled from "styled-components";
import "../styles/carousel.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "gatsby";
import MobileIframe from "../video/mobile-iframe";

const GalleryContainer = styled.div`
  margin-top: 70px;

  cursor: pointer;

  *:focus {
    outline: none;
  }

  & .carousel,
  & .carousel .slider-wrapper,
  & .carousel .slide {
    max-height: calc(${props => props.height}px - 300px);
  }

  @media (max-width: 768px) {
    margin-top: 125px;
  }

  @media (max-width: 500px) {
    // margin-top: 60px;
    margin-top: 90px;
  }

  @media (max-width: 320px) {
    margin-top: 60px;
  }
`;

const InnerGalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  max-height: calc(${props => props.height}px - 300px);
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  padding: 0;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%;
  }

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    padding: 0;

    max-height: calc(${props => props.height}px - 300px);
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const Caption = styled.div`
  margin-top: 9px;

  width: 26px;
  height: 36px;

  & div {
    width: 22px;
    height: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  & .current-slide {
    position: absolute;
    top: 0;
    // left: 0;
    left: 1px;
  }

  & .total-slides {
    position: absolute;
    // bottom: 0;
    bottom: 3px;
    right: 0;
  }

  & .separator {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    height: 22px;
  }

  & p {
    position: relative;
    margin: 0;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
  }

  & span:after {
    content: "";
    border-right: 1px solid #4a4a49;
    //#3f3f3e;
    //#000;
    content: "";
    position: absolute;
    width: 0;
    height: 27px;
    bottom: 0;
    left: 0;
    transform: rotate(45deg);
  }
`;

const CaptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;

  height: 100%;
  max-width: 720px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 10px 160px;
  }

  @media (max-width: 768px) {
    padding: 10px 90px;
  }

  @media (max-width: 600px) {
    padding: 10px 25px;
  }

  text-align: left;

  & p:last-of-type {
    margin-bottom: 0;
  }

  & h1,
  & p,
  & div {
    display: inline;
  }

  & h1,
  & p {
    margin-right: 1em;
  }

  & a {
    position: relative;
  }

  a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: #000;
    visibility: visible;
  }
`;

const Close = styled.button`
  padding: 9px 15px;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;

  color: #1d1d1b;
`;

const Headline = styled.div`
  display: block;

  & h1,
  & p {
    text-transform: uppercase;
    font-size: 12px;
    display: inline;
  }
`;

class MobileGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      totalSlides: 1,
      windowWidth: 769,
      playing: false,
    };
  }

  componentDidMount(props) {
    const slideTotal = this.props.content.length + 1;
    this.setState({
      totalSlides: slideTotal,
    });
  }

  next = () => {
    // console.log("next");
    this.setState(state => ({
      currentSlide: state.currentSlide + 1,
      playing: false,
    }));
  };

  prev = () => {
    // console.log("prev");
    this.setState(state => ({
      currentSlide: state.currentSlide - 1,
      playing: false,
    }));
  };

  updateCurrentSlide = index => {
    // console.log("updateCurrentSlide");
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState(state => ({
        currentSlide: index,
      }));
    }

    this.setState({
      playing: false,
    });
  };

  updateVideoPlayState = playState => {
    // console.log("updateVideoPlayState");
    this.setState(state => ({
      playing: playState,
    }));
  };

  render() {
    const galleryContent = this.props.content.map((content, index) => {
      if (content.slice_type === "image") {
        return (
          <ImageContainer key={index} height={this.props.windowHeight}>
            <div className="content">
              <img
                src={content.primary.image.url}
                alt={content.primary.image.alt}
              />
            </div>
          </ImageContainer>
        );
      } else if (content.slice_type === "video") {
        return (
          <MobileIframe
            key={content.primary.video.video_id}
            video={content.primary.video.html}
            thumbnail={content.primary.video.thumbnail_url}
            width={content.primary.video.width}
            height={content.primary.video.height}
            vimeoId={content.primary.video.video_id}
            autoPlay={false}
            playing={this.state.playing}
            updateVideoPlayState={this.updateVideoPlayState}
            // windowHeight={this.props.windowHeight}
          />
        );
      }
    });

    return (
      <>
        <GalleryContainer height={this.props.windowHeight}>
          <InnerGalleryContainer height={this.props.windowHeight}>
            <Carousel
              autoPlay={false}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              infiniteLoop={true}
              useKeyboardArrows={true}
              showArrows={false}
              selectedItem={this.state.currentSlide}
              onChange={this.updateCurrentSlide}
              emulateTouch={true}
              swipeable={true}
              interval={1e11}
              autoFocus={true}
            >
              {galleryContent}
              <Text>
                <Headline>
                  <h1>{this.props.data.title.text}</h1>
                  {/* <p>{this.props.data.location.text}</p> */}
                  <p>{this.props.data.role.text}</p>
                </Headline>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.text.html,
                  }}
                />
              </Text>
            </Carousel>
          </InnerGalleryContainer>
        </GalleryContainer>

        <CaptionContainer>
          <Caption>
            <div>
              <p className="current-slide">{this.state.currentSlide + 1}</p>
              <p className="separator">
                <span></span>
              </p>
              <p className="total-slides">{this.state.totalSlides}</p>
            </div>
          </Caption>

          <Close>
            <Link to={"/"}>( × ) close</Link>
          </Close>
        </CaptionContainer>
      </>
    );
  }
}

export default MobileGallery;
